<template>
  <nav>
    <input type="text" v-model="msg" placeholder="Filtruj" >
  </nav>
  <div>
    <p>parent name: {{ name }}</p>
    <button @click="change">parent change</button>
  </div>
  <h1>{{ msg }}</h1>
  <input @input="onChange" />
{{ msg }}
  <div class="comments">
    <Comment 
      v-for="comment in commentsData"
      :key="comment.id"
      :name="name"
      v-bind="comment"
    />
  </div>
</template>
<script setup>
const name = ref("karino");
const change = () => {
      name.value = "michihiko";
    };
const onChange = (e) => {
  console.log("777" + e.target.value);
  if (e.target.value === "Jhon") sayHello();
};
const sayHello = () => alert("hello");

defineProps({
  msg: String,
});


const msg = ref('Hello Worssssld!')
</script>
<script>
import { ref ,defineProps} from "vue";
import axios from "axios";
import Comment from "./Comment.vue";
const commentsData = ref([])

export default{
  mounted() {
    this.fetchPosts();
  },
  methods: {
        fetchPosts() {
          axios.post(location.protocol + "//" + location.hostname + "/")
                .then(response => commentsData.value = response.data)
                .catch(error => console.log(error))
        }
    },
  data() {
    return {
      commentsData
    };
  },
  components: {
    Comment,
    // Fancybox
  },
  computed: {
    comments() {
      return [...this.commentsData];
    },
  },
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
