<template>
  <div>
    <div  class="comment">
      <header>
        <!-- <h3>{{createdAt}}=================</h3> -->
        <!-- <h3>{{ listfiles }}</h3> -->
        {{ currentUrl.slice(0,16) }}<br>
        <!-- {{ host }} -->
        {{ vshow }}
        {{ Date() }}
        
        <h3>{{ dir_name }}</h3>
        <span v-html="icon"></span>
      </header>
      <!-- <div v-html="body" class="comment-body" /> -->
       {{ searchInfo }}
       <div>
        <p>child name: {{ name }}</p>
        <button @click="change">child change</button>
      </div>
      <Fancybox>
        <!-- <div v-for="file in listfiles" :key="file.id"> -->
        <div>
          <a
            v-for="file in listfiles"
            :key="file.id"
            data-fancybox="gallery"
            v-bind:href="'https://www.testvu.eu/' + dir_name + '/' + file"
          >
            <img
              v-bind:src="'https://www.testvu.eu/' + dir_name + '/' + file"
              width="200"
              height="150"
            />
          </a>
        </div>
        <!-- </div> -->
      </Fancybox>
    </div>
    <div v-if="replies && vshow" class="comment-replies">
      <div v-for="reply in replies" :key="reply.id">
        <div >
          <Comment  v-bind="reply[0]" :name="name" type="reply"> </Comment>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import moment from 'moment'

import Fancybox from "./Fancybox.vue";
import { toRef } from 'vue';

export default {
  name: "Comment",
  created() {
    this.vshow =this.currentUrl.slice(0,16)// false;//this.equal_dates("04.06.2024", this.createdAt) 
    console.log(this.currentUrl.slice(0,16));
    // console.log(this.createdAt);
    // console.log("from mounted Comment " + new Date(this.createdAt).getFullYear());
    // console.log("from mounted Comment new DATE() " + new Date('2024.05.31').getFullYear());
  },
  data() {
    return { 
      currentUrl: location.toString(),
      host: location.host,
      vshow: null//this.equal_dates("06.06.2024", this.createdAt) 
    };
  },
  setup(props) {
    const name = toRef(props, 'name');

    const change = () => {
      console.log('child change')
      name.value = 'michihiko2';
    };

    return {  change };
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    authorFullName: { type: String, required: true },
    body: { type: String, required: true },
    createdAt: { type: Date, required: false },
    dir_name: { type: String, required: false },
    replies: { type: Array, required: true },
    type: { type: String, required: false, default: "comment" },
    listfiles: { type: Array, required: false },
  },
  components: {
    Fancybox,
  },
};
</script>

<style lang="css" scoped>
.comment {
  border: 1px solid DodgerBlue;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  padding: 1.5rem;
}

h3,
p {
  margin: 0;
}

header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.75rem;
}

svg {
  fill: SlateGray;
}

.comment-body {
  margin-bottom: 0.375rem;
}

.timestamp {
  color: DimGray;
  font-size: 0.8rem;
}
.comment-replies {
  padding-left: 3.5rem;
}
</style>
